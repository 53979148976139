@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideFromBottom {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes slideToBottom {
  0% {
    bottom: 0%;
  }
  100% {
    bottom: -100%;
  }
}
