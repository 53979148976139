@use "../../theme/utils/mixins.scss";

.tecma-carousel {
  outline: none;
  max-width: 100vw;
  box-shadow: 0 0 1.2rem -1rem;
  position: relative;
  margin: 0;
  overflow: hidden;
  height: 100%;

  .carousel-buttons-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 3rem;
    transform: unset;
    display: flex;
    gap: 1rem;
    justify-content: center;
    .carousel-button {
      padding: 0.5rem;
    }
  }

  .slider-wrapper {
    overflow-x: hidden;
    margin: auto;
    width: 100%;
    display: flex;
    height: 100%;
    transition: height 0.15s ease-in;
    &.centered {
      justify-content: center;
      ul {
        width: 50%;
      }
    }
    .slider {
      -ms-box-orient: horizontal;
      display: flex;
      .slide {
        display: flex;
        min-width: 100%;
        transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        justify-content: center;
        align-items: center;
        opacity: 0;
        height: 100%;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        > * {
          overflow: hidden;
        }
        &.selected {
          opacity: 1;
        }
        &.selected,
        &.next,
        &.previous {
          z-index: 0;
        }
      }
    }
  }

  .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
    transition: all 0.35s ease-in-out;
  }

  .control-dots {
    position: absolute;
    bottom: 0;
    margin: 0.625rem 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;

    .dot {
      @include mixins.opacity(0.3);
      transition: opacity 0.25s ease-in;
      box-shadow: 0.063rem 0.063rem 0.125rem rgba(#000, 0.9);
      background: #fff;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      cursor: pointer;
      display: inline-block;
      margin: 0 0.5rem;
      border: none;
      padding: 0;

      &.selected,
      &:hover {
        @include mixins.opacity(1);
      }
    }
  }
}
