@use "../../theme/utils/colors" as *;
@use "../../theme/utils/mixins" as *;

.tecma-toggle-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .toggle-label {
    font-size: 0.875rem;
    color: $on-general-sub;
  }
  .tecma-toggle {
    width: 3rem;
    height: 1.5rem;
    background-color: $negative;
    border-radius: 100rem;
    position: relative;
    padding: 0.125rem;
    transition: background-color 0.5s;
    &:focus {
      outline: 0.188rem solid opaqueColor($accent, 0.3);
    }
    .toggle-element {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 100%;
      background-color: $on-negative;
      transform: translateX(0);
      transition: transform 0.5s;
      cursor: pointer;
    }
    &.active {
      background-color: $accent;
      transition: background-color 0.5s;
      .toggle-element {
        transform: translateX(100%);
        transition: transform 0.5s;
      }
    }
    &.disabled {
      background-color: $disabled;
      .toggle-element {
        background-color: $on-disabled;
        pointer-events: none;
      }
    }
    // ----------------------------
    // SIZES
    // ----------------------------
    &.large {
      width: 4rem;
      height: 2rem;
      .toggle-element {
        width: 2rem;
        height: 2rem;
      }
    }
    &.small {
      width: 2rem;
      height: 1rem;
      .toggle-element {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .toggle-helpText {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    &-errored {
      color: $danger;
    }
  }
}
