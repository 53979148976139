@use "../../theme/utils/variables" as *;
@use "../../theme/utils/mixins" as *;

.tecma-pagination {
  display: flex;
  .tecma-button.transparent:not(.disabled) {
    @include iconColor($primary);
  }
  .pagination-page {
    &.is-selected:not(.disabled):not(.outlined) {
      background-color: $primary;
      color: $neutral-100;
    }
    &.is-not-page {
      pointer-events: none;
    }
  }
}
