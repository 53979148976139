@use "../../../../theme/utils/colors" as *;

.tecma-sidebarFooter {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(.isOpen){
    visibility: hidden;
  }
}
