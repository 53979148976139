@use "../../theme/utils/colors" as *;
@use "../../theme/utils/fonts" as *;
@use "../../theme/utils/mixins" as *;

.tecma-alert {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid;
  border-radius: 0.125rem;
  padding: 1rem;
  gap: 1rem;
  min-width: 23.5rem;
  .alert-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
    font-size: 0.875rem;
    .alert-title {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 600;
      color: $on-general;
    }
    .alert-description {
      margin: 0;
      font-weight: 400;
      color: $on-general-sub;
    }
  }
  &.default {
    border-color: $general-border;
    background-color: $general;
    @include iconColor($on-general);
  }
  &.success {
    border-color: $success;
    background-color: $success-light;
    @include iconColor($success);
  }
  &.warning {
    border-color: $warning;
    background-color: $warning-light;
    @include iconColor($warning);
  }
  &.error {
    border-color: $danger;
    background-color: $danger-light;
    @include iconColor($danger);
  }
  &.informative {
    border-color: $info;
    background-color: $info-light;
    @include iconColor($info);
  }
  .tecma-button.medium.icon-only {
    width: 1.25rem;
    height: 1.25rem;
    outline: none;
    .tecma-icon {
      path {
        fill: $on-general;
      }
    }
  }
}
