@use "../../theme/utils/colors" as *;
@use "../../theme/utils/fonts" as *;
@use "../../theme/utils/dimensions" as *;

.tecma-textArea {
  display: flex;
  flex-direction: column;
  .textArea-counter {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.25rem;
    font-size: $smallFont;
    color: $on-general-sub;
  }
  .textArea-label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    color: $on-general;
  }
  &.invalid {
    textarea {
      border-color: $danger;
    }
  }
  textarea {
    padding: 0.5rem 1rem;
    scroll-behavior: auto;
    color: $on-general;
    border-color: $general-border;
    border-radius: $border-radius-external;
    &:focus,
    &:active {
      outline: 0.188rem solid opaqueColor($general-border, 0.3);
    }
    &::placeholder {
      color: $on-general-sub;
    }
    &::-webkit-scrollbar {
      width: 1rem;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0.5rem transparent;
      margin: 0.25rem 0;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      border: 0.2rem solid transparent;
      background: $gray-700;
      background-clip: padding-box;
    }
  }
}
