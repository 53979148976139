@use "../../theme/utils/variables.scss" as *;

.date-picker-mobile-button {
  min-width: 5rem;
  color: $on-general-sub;
}
.tecma-modal-container .tecma-modal-header__content__subtitle {
  color: $on-general-sub;
}

.tecma-modal-container {
  .date-picker-mobile-modal {
    height: 90%;
    display: flex;
    flex-direction: column;
    &__header {
      justify-content: space-between;
      align-items: center;
    }
    &__content {
      max-height: unset;
      padding: 0;
    }
    &__footer {
      width: 100%;
      flex-direction: column;
      box-sizing: border-box;
      .tecma-button.rounded {
        border-radius: $border-radius-element;
      }
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after,
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
      color: $primary-light;
    }
    .Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
      border-radius: $border-radius-element;
      border: 1px solid $primary-border;
    }
    .Cal__Today__root.Cal__Today__show {
      background-color: $primary !important;
      color: $on-primary !important;
    }
    .Cal__Years__year {
      span,
      label {
        cursor: pointer;
      }
      &:hover span {
        color: $primary !important;
      }
    }
    .Cal__Years__year.Cal__Years__currentYear span {
      border: 0;
    }
    .Cal__Years__year.Cal__Years__active span {
      background-color: $primary !important;
      color: $on-primary !important;
      display: flex;
      height: 2.5rem;
      min-width: 2.5rem;
      padding: 0.5rem $spacing-m;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius-element;
      font-size: 1.125rem;
    }
    .Cal__Weekdays__root,
    .Cal__Header__root {
      background-color: $general !important;
      color: $on-general !important;
    }
    .Cal__Weekdays__day {
      color: on-general-sub;
      text-align: center;
      font-family: "Proxima Nova", sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
    .Cal__Day__root.Cal__Day__enabled:hover::before {
      border-radius: $border-radius-element;
    }
    .Cal__Header__root {
      border-bottom: 1px solid $general-border;
      padding: $spacing-s $spacing-m;
      .Cal__Header__blank {
        color: $on-general;
      }
      .Cal__Header__year {
        color: $on-general-sub;
        font-family: "Proxima Nova", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        height: 1.5rem;
      }
      .Cal__Header__day {
        height: 2rem;
        color: $on-general;
        font-family: "Proxima Nova", sans-serif;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
      }
    }
    .Cal__Today__root.Cal__Today__chevronUp .Cal__Today__chevron {
      display: none;
    }
    .Cal__Header__range .Cal__Header__wrapper:first-child:before,
    .Cal__Header__range .Cal__Header__wrapper:first-child:after {
      display: none;
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
      border-color: $primary-border !important;
      .Cal__Day__day {
        top: -4px;
      }
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
      border-color: $primary-border !important;
      .Cal__Day__day {
        top: -2px;
      }
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__enabled.Cal__Day__betweenRange
      .Cal__Day__selection {
      background-color: $primary-light !important;
      .Cal__Day__day {
        color: $on-primary-light !important;
        font-weight: bold;
      }
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__enabled .Cal__Day__selection {
      background-color: $primary !important;
      .Cal__Day__day {
        color: $on-primary !important;
        font-weight: bold;
      }
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__disabled .Cal__Day__selection {
      background-color: $primary-light !important;
      .Cal__Day__day {
        color: $on-disabled !important;
      }
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__selection .Cal__Day__selection,
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection,
    .Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
      background-color: $primary !important;
      color: $on-primary !important;
      border: 1px solid $primary-border !important;
      border-top-left-radius: unset;
      border-top-left-radius: unset;
      border-radius: $border-radius-element;
      .Cal__Day__month {
        display: none;
      }
      .Cal__Day__day {
        color: $on-primary !important;
        font-weight: bold;
      }
    }
    .Cal__Day__selected {
      color: $on-primary !important;
      .Cal__Day__selection {
        background-color: $primary !important;

        .Cal__Day__day {
          top: -1px;
          color: $on-primary;
        }
        .Cal__Day__month {
          display: none;
        }
      }
    }
    .Cal__Day__today {
      color: $general-border !important;
    }
    .Cal__Container__root,
    .Cal__Years__root {
      display: flex;
      flex-direction: column;
      height: 100% !important;
    }
    .Cal__Years__root {
      height: calc(100% - 98px) !important;
      .Cal__Tears__list {
        height: 100%;
      }
    }
    .Cal__Container__wrapper {
      height: calc(100% - 98px);
      display: flex;
      flex-direction: column;
    }
  }
}
