@use "../../theme/utils/colors" as *;
@use "../../theme/utils/dimensions" as *;
@use "../../theme/utils/mixins" as *;

.tecma-select {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  .control-isLoading {
    pointer-events: none;
  }
  .help-text {
    font-size: 0.75rem;
    color: $danger;
  }
  .single-value {
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--on-general, #3b3b3b);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
  }
  .input-container {
    height: auto !important;
    min-height: 1.5rem;
    padding: 0.5rem 0.75rem;
    input {
      order: 1;
      &::placeholder {
        font-size: 0.875rem;
      }
    }
    svg {
      width: 1.25rem;
      height: 1.25rem;
      min-width: 1.25rem;
      min-height: 1.25rem;
    }
  }
  .placeholder {
    color: var(--on-general-active, #767676);
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    display: inline-block;
    line-height: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
    overflow: hidden;
    -webkit-box-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    max-width: unset;
  }
  &-label {
    font-size: 0.875rem;
    font-family: Lato, sans-serif;
    color: $on-general;
  }
  label.tecma-checkbox {
    margin-right: 7px;
  }
  .error-icon {
    position: absolute;
    right: 0;
    path {
      fill: $danger;
    }
  }
  .option-wrapper {
    font-family: Lato, sans-serif;
    display: flex;
    gap: $spacing-xs;
    align-items: center;
  }
  &-option-icon {
    margin-top: 2px;
    margin-right: 0.65rem;
    margin-left: 0.2rem;
  }
  &-box {
    width: 17rem;
    &.fluid {
      width: auto;
    }
    .dropdownIndicator {
      transform: rotate(0);
      transition: 0.1s;
      &.isOpen {
        transform: rotate(180deg);
      }
    }
  }
  .checkIcon path {
    fill: $accent;
  }
}
