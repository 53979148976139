@use "../../../theme/utils/variables.scss" as *;

@keyframes tecmaSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes tecmaSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}

@keyframes tecmaSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes tecmaSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}

.tecma-date-picker-wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
}

label.tecma-date-picker-label {
  font-size: 0.875rem;
  font-family: Lato, sans-serif;
  color: $on-general;
}

.tecma-date-picker {
  box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  padding: 8px 11px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: $neutral-100;
  border: 1px solid $general-border;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
  &:hover,
  &-focused {
    border-color: $primary-focus;
    border-right-width: 1px;
  }
  &-footer {
    display: none;
  }

  &-focused {
    box-shadow: 0 0 0 2px rgba(107, 193, 180, 0.2);
    outline: 0;
  }
  &.tecma-date-picker-disabled {
    background: $semantic-disabled-light;
    border-color: $primary;
    cursor: not-allowed;
    .tecma-date-picker-suffix {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &.tecma-date-picker-borderless {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  &.tecma-date-picker-border-bottom {
    background-color: transparent !important;
    box-shadow: none !important;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    &.tecma-date-picker-focused {
      border-left-color: $primary-focus;
      border-right-color: $primary-focus;
      border-top-color: $primary-focus;
    }
  }

  // ======================== Input =========================
  &-input {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;

    > input {
      position: relative;
      display: inline-block;
      width: 100%;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      line-height: 1.5715;
      border-radius: 2px;
      transition: all 0.3s;
      flex: auto;
      min-width: 1px;
      height: auto;
      background: transparent;
      border: 0;
      text-align: center;
      padding: 0;
      text-transform: capitalize;
      &::-moz-placeholder {
        opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
      }

      &::placeholder {
        color: hsl(0, 0, 75%);
        user-select: none;
      }

      &:placeholder-shown {
        text-overflow: ellipsis;
      }
      &:hover {
        border-color: $primary-hover;
        border-right-width: 1px;
        // MAYBE TO REMOVE
        .tecma-date-picker-input-rtl {
          border-right-width: 0;
          border-left-width: 1px !important;
        }
      }

      &:focus,
      &.tecma-date-picker-focused {
        border-color: $primary-focus;
        box-shadow: 0 0 0 2px rgba(107, 193, 180, 0.2);
        border-right-width: 1px;
        outline: 0;
      }

      &-disabled {
        color: rgba($neutral-000, 0.25);
        background-color: hsl(0, 0, 96%);
        border-color: hsl(0, 0, 85%);
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;

        &:hover {
          border-color: hsl(0, 0, 85%);
          border-right-width: 1px;
        }
      }

      &[disabled] {
        color: rgba($neutral-000, 0.25);
        background-color: hsl(0, 0, 96%);
        border-color: hsl(0, 0, 85%);
        box-shadow: none;
        cursor: not-allowed;
        opacity: 1;

        &:hover {
          border-color: hsl(0, 0, 85%);
          border-right-width: 1px;
        }
      }

      &-borderless {
        &:hover,
        &:focus,
        &-focused,
        &-disabled,
        &[disabled] {
          background-color: transparent;
          border: none;
          box-shadow: none;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }

    &:hover {
      .tecma-date-picker-clear {
        opacity: 1;
      }
    }

    &-placeholder {
      > input {
        color: hsl(0, 0, 75%);
        text-transform: capitalize;
      }
    }
  }

  // Size
  &-large {
    padding: 6.5px 11px 6.5px;
    .tecma-date-picker-input > input {
      font-size: 16px;
    }
  }

  &-small {
    padding: 0px 7px 0px;
  }

  &-suffix {
    order: -1;
    display: flex;
    flex: none;
    align-self: center;
    color: rgba(0, 0, 0, 0.25);
    line-height: 1;
    pointer-events: none;

    > * {
      vertical-align: top;
      font-size: 1rem;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  &-clear {
    position: absolute;
    top: 50%;
    left: 0.675rem;
    color: rgba($neutral-000, 0.25);
    font-size: 16px;
    line-height: 1;
    background: $neutral-100;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;

    > * {
      vertical-align: top;
    }

    &:hover {
      color: rgba($neutral-000, 0.45);
    }
  }

  &-separator {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 16px;
    color: rgba($neutral-000, 0.25);
    font-size: 16px;
    vertical-align: top;
    cursor: default;

    .tecma-date-picker-focused & {
      color: rgba($neutral-000, 0.45);
    }

    .tecma-date-picker-range-separator & {
      .tecma-date-picker-disabled & {
        cursor: not-allowed;
      }
    }
  }

  // ======================== Range =========================
  &-range {
    position: relative;
    display: inline-flex;
    width: 100%;

    &:hover {
      .tecma-date-picker-clear {
        opacity: 1;
      }
    }

    // Active bar
    .tecma-date-picker-active-bar {
      bottom: -1px;
      height: 2px;
      background: $primary-focus;
      opacity: 0;
      transition: all 0.3s ease-out;
      pointer-events: none;
    }

    &.tecma-date-picker-focused {
      .tecma-date-picker-active-bar {
        opacity: 1;
      }
    }

    &-separator {
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1;
    }

    &.tecma-date-picker-small {
      .tecma-date-picker-clear {
        right: 7px;
      }

      .tecma-date-picker-active-bar {
        margin-left: 7px;
      }
    }
  }

  // ======================= Dropdown =======================
  &-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba($neutral-000, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1300;
    position: fixed;

    &-hidden {
      display: none;
    }

    &.tecma-slide-up-enter.tecma-slide-up-enter-active.tecma-date-picker-dropdown-placement-topLeft,
    &.tecma-slide-up-enter.tecma-slide-up-enter-active.tecma-date-picker-dropdown-placement-topRight,
    &.tecma-slide-up-appear.tecma-slide-up-appear-active.tecma-date-picker-dropdown-placement-topLeft,
    &.tecma-slide-up-appear.tecma-slide-up-appear-active.tecma-date-picker-dropdown-placement-topRight {
      animation-name: tecmaSlideDownIn;
    }

    &.tecma-slide-up-enter.tecma-slide-up-enter-active.tecma-date-picker-dropdown-placement-bottomLeft,
    &.tecma-slide-up-enter.tecma-slide-up-enter-active.tecma-date-picker-dropdown-placement-bottomRight,
    &.tecma-slide-up-appear.tecma-slide-up-appear-active.tecma-date-picker-dropdown-placement-bottomLeft,
    &.tecma-slide-up-appear.tecma-slide-up-appear-active.tecma-date-picker-dropdown-placement-bottomRight {
      animation-name: tecmaSlideUpIn;
    }
    &.tecma-slide-up-leave.tecma-slide-up-leave-active.tecma-date-picker-dropdown-placement-topLeft,
    &.tecma-slide-up-leave.tecma-slide-up-leave-active.tecma-date-picker-dropdown-placement-topRight {
      animation-name: tecmaSlideDownOut;
    }
    &.tecma-slide-up-leave.tecma-slide-up-leave-active.tecma-date-picker-dropdown-placement-bottomLeft,
    &.tecma-slide-up-leave.tecma-slide-up-leave-active.tecma-date-picker-dropdown-placement-bottomRight {
      animation-name: tecmaSlideUpOut;
    }
  }

  &-dropdown-range {
    padding: calc(8px * 1.4 * 2 / 3) 0;

    &-hidden {
      display: none;
    }
  }

  // ======================== Ranges ========================
  &-ranges {
    margin-bottom: 0;
    padding: calc(8px / 2) 12px;
    overflow: hidden;
    line-height: 40px - 2 * 1px - calc(8px / 2);
    text-align: left;
    list-style: none;

    > li {
      display: inline-block;
    }

    .tecma-date-picker-preset > .tecma-date-picker-tag-blue {
      color: $primary;
      background: rgba($primary, 0.9);
      border-color: rgba($primary, 0.8);
      cursor: pointer;
    }

    .tecma-date-picker-ok {
      float: right;
      margin-left: 8px;
    }
  }

  &-range-wrapper {
    display: flex;
  }

  &-panel-container {
    overflow: hidden;
    position: relative;
    vertical-align: top;
    background: $neutral-100;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: margin 0.3s;

    .tecma-date-picker-panels {
      display: flex;
      flex-wrap: wrap;
      direction: ltr;
      justify-content: center;
      width: max-content;
      max-width: 50vw;
      @media (max-width: 599px) {
        max-width: 100vw;
      }
      @media (min-width: 959px) and (max-width: 1279px) {
        max-width: 60vw;
      }
    }

    .tecma-date-picker-panel {
      vertical-align: top;
      background: transparent;
      border-width: 0 0 1px 0;
      border-radius: 0;
      width: 280px;
      .tecma-date-picker-content,
      table {
        text-align: center;
      }

      &-focused {
        border-color: rgba(0, 0, 0, 0.06);
      }
    }
  }
}

@import "./panel.scss";

@import "./rtl.scss";

@import "./status";
