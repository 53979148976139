.tecma-progressIndicator {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 0.5rem;
  gap: 0.5rem;
  transition: all 1s;
  .stepProgressBar {
    height: 100%;
    width: 1.5rem;
    transition: all 1s;

    &.currentStep {
      flex: 1;
    }
  }
  .value {
    position: absolute;
    right: 0;
    margin: 0;
    top: -1.5rem;
  }
}
