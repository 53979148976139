@use "../../../theme/utils/variables.scss" as *;

.tecma-picker {
  &-status-error {
    border:1px solid $semantic-alert;
    &:not([disabled]):hover {
      background-color: $neutral-100;
      border-color: $semantic-alert-dark;
    }

    &-focused,
    &:focus {
      border-color: $semantic-alert;
      box-shadow: 0 0 0 2px $semantic-alert;
      border-right-width: 1px;
      outline: 0;
    }
  }

  &-status-warning {
    border:1px solid $semantic-warning;
    &:not([disabled]):hover {
      background-color: $neutral-100;
      border-color: $semantic-warning-dark;
    }

    &-focused,
    &:focus {
      border-color: $semantic-warning;
      box-shadow: 0 0 0 2px $semantic-warning;
      border-right-width: 1px;
      outline: 0;
    }
  }
}
