@use "../../theme/utils/colors" as *;

.tecma-divider {
  border: 0.0625rem solid;
  border-color: $general-border;
 
  &.vertical{
    margin: 0 1rem;
    display: inline;
    height: 1rem;
  }
}
