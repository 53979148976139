@use "../../theme/utils/colors" as *;
@use "../../theme/utils/fonts" as *;
@use "./radioButtonMixins.scss" as *;

.tecma-radioButton {
  display: flex;
  align-items: center;
  gap: 1rem;
  &.top {
    flex-direction: column-reverse;
  }
  &.bottom {
    flex-direction: column;
  }
  &.left {
    flex-direction: row-reverse;
  }
  .radioButton-container {
    background-color: $general;
    border: 1px solid $general-border;
    padding: 0.125rem;
    border-radius: 100rem;
    box-sizing: border-box;
    .radioButton-element {
      border-radius: 100rem;
      cursor: pointer;
    }
  }
  // ----------------------------
  // SIZES
  // ----------------------------
  &.small {
    .radioButton-container {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
      min-height: 1rem;
    }
    &.radio-card {
      padding: 0rem 0.875rem;
      font-size: $smallFont;
      height: 2rem;
    }
  }

  &.medium {
    .radioButton-container {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
    }
    &.radio-card {
      padding: 0rem 1rem;
      font-size: $defaultFont;
      height: 2.5rem;
    }
  }

  &.large {
    .radioButton-container {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
    }
    &.radio-card {
      padding: 0rem 1.5rem;
      font-size: $largeFont;
      height: 3rem;
    }
  }

  // ----------------------------
  // COLORS
  // ----------------------------
  @include radioButton-with-color("primary", $accent);
  @include radioButton-with-color("secondary", $secondary);
  @include radioButton-with-color("danger", $accent);

  &.checked {
    .radioButton-element {
      width: 100%;
      height: 100%;
    }
  }

  &.disabled {
    pointer-events: none;
    background-color: $general;
    color: $on-disabled;
    &.checked {
      border-color: $disabled;
      .radioButton-element {
        background-color: $on-disabled;
      }
    }
  }

  &.errored:not(&.disabled):not(.radio-card) {
    .radioButton-container {
      border-color: $general-border;
      outline: 0.125rem solid $danger;
    }
    &.checked {
      .radioButton-element {
        background-color: $accent;
      }
    }
  }

  &.radio-card {
    transition: all 0.3s ease;
    box-sizing: border-box;
    border-radius: 0.125rem;
    padding: 0rem 1rem;
    background-color: transparent;
    border: 0.063rem solid;
    cursor: pointer;
    @include radioButton-card-with-color(
      "primary",
      $on-general,
      $general-border,
      $accent,
      $accent-light
    );
    @include radioButton-card-with-color("errored", $danger, $danger, $danger, $danger-light);
    &.disabled {
      @include borderColor($disabled, $on-disabled);
      &.errored {
        border-color: $danger;
      }
    }

    .radioButton-content {
      width: 100%;
    }
  }
}
