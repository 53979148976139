@use "../../theme/utils/dimensions" as *;
@use "../../theme/utils/breakpoints" as *;
@use "../../theme/utils/colors" as *;
@use "../../theme/utils/aniamations.scss" as *;

.tecma-modal-container {
  display: flex;
  justify-content: center;
  padding: 0;
  position: fixed;
  inset: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);

  &.top {
    align-items: flex-start;
  }
  &.center {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }

  .tecma-modal {
    box-sizing: border-box;
    position: absolute;
    width: 25rem;
    min-width: 10rem;
    max-width: fit-content; //useful for mobile -> to be verified
    background-color: $general;
    padding: 0;
    border-radius: $border-radius-external;
    box-shadow: 0 0 2px 0px $secondary;

    &.small {
      width: 20rem;
    }
    &.medium {
      width: 40rem;
    }
    &.large {
      width: 60rem;
    }

    &-header {
      display: flex;
      padding: 1.5rem;
      background-size: 100%;
      background-position: center;
      gap: $spacing-xxs;
      border-top-left-radius: $border-radius-external;
      border-top-right-radius: $border-radius-external;

      &.dark-background {
        .tecma-modal-header__content {
          color: $inverse;
        }
        .tecma-modal-header__close-icon {
          outline-color: $inverse !important;
          &:hover svg path {
            fill: $inverse !important;
          }
          svg path {
            fill: $inverse !important;
          }
        }
      }

      @media screen and (max-width: $tablet) {
        padding: 1rem 1.5rem;
        background-image: none !important;
        border-bottom: 1px solid $general-border;
        &.dark-background {
          .tecma-modal-header__content {
            color: currentColor;
          }
          .tecma-modal-header__close-icon {
            outline-color: currentColor !important;
            &:hover svg path {
              fill: currentColor !important;
            }
            svg path {
              fill: currentColor !important;
            }
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__title {
          font-family: Lato, sans-serif;
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2rem;
        }
        &__subtitle {
          font-family: Lato, sans-serif;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5rem;
        }
      }

      &.closeIcon {
        justify-content: space-between;

        .tecma-button {
          padding: 0;
        }
      }
    }

    &-content {
      max-height: 60vh;
      overflow: auto;
      padding: 1rem 1.5rem;
    }

    &-footer {
      display: flex;
      justify-content: flex-end;
      align-self: center;
      flex-wrap: wrap;
      gap: 0.5rem;
      padding: 1.5rem;
      border-top: 1px solid $general-border;

      &.noWrapAndCenter {
        //useful for dual button layout or no wrap in general
        justify-content: space-around;
        flex-wrap: nowrap;
      }
      @media screen and (max-width: $tablet) {
        box-shadow: 0px -4px 12px 0px rgba(212, 212, 212, 0.25);
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .tecma-modal {
      animation: slideToBottom 0s;
    }
    &.isOpen {
      .tecma-modal {
        animation: slideFromBottom 0s;
      }
    }
    &:not(.disable-mobile-animation) {
      animation: fadeOut 1s;
      &.top,
      &.bottom,
      &.center {
        padding: unset;
        align-items: flex-end;
        .tecma-modal {
          min-width: 100%;
          animation: slideToBottom 1s linear;
        }
      }
      &.isOpen {
        animation: fadeIn 1s;
        .tecma-modal {
          animation: slideFromBottom 1s linear;
        }
      }
    }
  }
}
