@use "../../theme/utils/colors" as *;

.tecma-radio {
  .MuiTypography-root {
    font-size: 0.875rem;
    color: $on-general;
  }
  .radioButton-container {
    background-color: $general;
    border: 1px solid $general-border;
    padding: 0.25rem;
    border-radius: 100rem;
    box-sizing: border-box;

    .radioButton-element {
      border-radius: 100rem;
      cursor: pointer;
    }

    &.checked {
      border-color: $accent;
      .radioButton-element {
        background-color: $accent;
        width: 100%;
        height: 100%;
      }
    }

    &.small {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
      min-height: 1rem;
    }

    &.medium {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
    }

    &.large {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
      min-height: 2rem;
    }
  }

  &.MuiFormControlLabel-root {
    gap: 1rem;
    margin-right: 0;
    margin-left: 0;
  }

  .Mui-focusVisible {
    .radioButton-container {
      outline: 2px solid rgba($primary, 30%);
    }
  }

  .MuiRadio-colorError {
    .radioButton-container {
      &.checked {
        border-color: $general-border;
      }
      outline: 2px solid $danger;
    }
  }

  .MuiFormControlLabel-label {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .MuiRadio-root {
    padding: 0;
  }
}
