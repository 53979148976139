@use "../../theme/utils/colors" as *;
@use "../../theme/utils/fonts" as *;

.tecma-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;
  background-color: $primary;
  .tecma-header-logo {
    width: 7.5rem;
    height: 2rem;
  }
  .tecma-header-utility-menu {
    display: flex;
    gap: 1rem;
    .tecma-button.transparent {
      color: $on-primary;
      .tecma-icon path {
        fill: $on-primary;
      }
    }
    .tecma-header-item {
      outline: none;
      gap: 0.5rem;
      max-width: 15rem;
      text-transform: capitalize;
      transition: color 0.25s ease, background-color 0.25s ease;
      @media (pointer: fine) {
        &:hover {
          background-color: $primary-active;
        }
      }
      span {
        font-family: $primaryFont;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .tecma-header-item-trigger {
      display: flex;
      align-items: center;
      outline: none;
      gap: 0.5rem;
      max-width: 15rem;
      text-transform: capitalize;
      transition: background-color 0.25s ease;
      &:hover,
      &.open {
        background-color: $primary-active;
      }
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    #language-selector-button,
    .language-selector {
      border: none;
      gap: 0.5rem;
      transition: background-color 0.25s ease;
      &.outlined.tecma-button {
        color: $on-primary;
        box-shadow: none;
        .tecma-icon path {
          fill: $on-primary;
        }
      }
      &:hover,
      &.open {
        background-color: $primary-active;
      }
    }
  }
}

.tecma-header-item {
  ul {
    max-width: 15rem;
    min-width: 10.625rem;
    padding: 0;
    .tecma-header-menu-item {
      .tecma-button,
      .not-clickable-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 3rem;
        height: auto;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        text-transform: capitalize;
        .menu-item-title {
          font-size: 0.875rem;
          line-height: 1.5rem;
        }
        .menu-item-subtitle {
          font-size: 0.75rem;
          line-height: 1rem;
          color: $on-general-sub;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
      }
    }
    .tecma-header-menu-item-divider {
      width: 100%;
      height: 0.0625rem;
      background-color: $general-border;
      margin: 0.125rem 0;
    }
  }
}

.tecma-header-language-selector {
  ul {
    min-width: 11.25rem;
  }
}
